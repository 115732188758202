<template>
  <div class="index">
    <div class="aside">
      <div class="top" v-show="!isCollapse">
        <!-- <img src="../assets/imgs/login-logo1.png" alt="" class="img" /> -->
        <p class="img" style="width:109px;height:25px"></p>
        <!-- <p class="title_main">视频监控管理中心</p> -->
        <p class="title_main">智慧工地安全生产视频监督系统</p>
      </div>
      <el-menu
        :default-active="currentPath"
        class="el-menu-vertical-demo"
        :router="true"
        text-color="#444"
        :collapse="isCollapse"
        :unique-opened='true'
      >
        <!-- <el-menu-item index="/index">
          <i class="el-icon-menu"></i>
          <span slot="title">首页</span>
        </el-menu-item>
        <el-menu-item index="/index/Video">
          <i class="el-icon-menu"></i>
          <span slot="title">视频监控</span>
        </el-menu-item>
        <el-menu-item index="/index/Device">
          <i class="el-icon-menu"></i>
          <span slot="title">设备注册</span>
        </el-menu-item>
        <el-submenu index="1">
          <template slot="title">
            <i class="el-icon-location"></i>
            <span slot="title">系统设置</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/index/User_manage">用户管理</el-menu-item>
            <el-menu-item index="/index/Organization_manage"
              >组织管理</el-menu-item
            >
            <el-menu-item index="/index/Permission_manage"
              >权限管理</el-menu-item
            >
            <el-menu-item index="/index/Menu_manage">菜单管理</el-menu-item>
          </el-menu-item-group>
        </el-submenu>
        <el-submenu index="2">
          <template slot="title">
            <i class="el-icon-location"></i>
            <span slot="title">日志管理</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/index/Access_log">访问日志</el-menu-item>
            <el-menu-item index="/index/Operation_log">操作日志</el-menu-item>
          </el-menu-item-group>
        </el-submenu> -->

        <div  v-for="(item,index) in menuData"
          :key="item.url">  
          <el-submenu               
           v-if="item.children&&item.isShow == 1&&item.children[0].menuType==1"
          :index="index+''"
        >
          <template slot="title">
            <i :class="item.menuIcon"></i>
            <span slot="title">{{ item.name }}</span>
          </template>
          <el-menu-item-group>
            <el-menu-item
              v-for="item1 in item.children"
              :key="item1.name"
              :index="item1.url"
              @click="addList(item1.url, item1.name, item.sort)"
              >{{ item1.name }}</el-menu-item
            >
          </el-menu-item-group>
        </el-submenu>   

        <el-menu-item               

          v-else
          :index="item.url"
              @click="addList(item.url, item.name, item.sort)">
              <template slot="title">
            <i :class="item.menuIcon"></i>
            <span slot="title">{{ item.name }}</span>
          </template>
              </el-menu-item>
        
      </div>
      </el-menu>
      <img
        src="../assets/imgs/menu.png"
        alt=""
        class="bottom_img"
        v-show="!isCollapse"
      />
    </div>
    <div class="main">
      <header>
        <div class="left">
          <div>
            <img
              style="
                width: 34px;
                height: 22px;
                margin-top: 10px;
                margin-left: 8px;
                margin-right: 10px;
              "
              src="../assets/imgs/menu1.png"
              alt=""
              v-show="menu === 1"
              @click="menuChange(2)"
            />
            <img
              style="
                width: 34px;
                height: 22px;
                margin-top: 10px;
                margin-left: 8px;
                margin-right: 10px;
              "
              src="../assets/imgs/menu3.png"
              alt=""
              v-show="menu === 2"
              @click="menuChange(1)"
            />
          </div>
          <p class="arrow" @click="$router.go(-1)"><span></span>返回</p>
          <div
            class="head"
            v-for="(item, index) in headList"
            :key="item.url"
            @mouseover="headactive = index"
            @mouseout="headactive = 100"
            @click="$router.push(item.url)"
            :class="{ head_active: item.url == $route.path }"
          >
            <transition name="slide-fade">
              <span
                class="head_border"
                v-show="item.url == $route.path || index == headactive"
              ></span>
            </transition>
            <span>{{ item.name }}</span>
            <span
              class="close"
              v-show="headList.length < 17"
              @click.stop="deleteItem(item)"
              ><img
                class="close_img"
                src="../assets/imgs/colse_index.png"
                alt=""
            /></span>
          </div>
        </div>

        <div class="right">
          <span
            @click="colse"
            style="cursor: pointer"
            v-show="headList.length > 1"
            >关闭标签</span
          >
          <div class="message">
            <span class="orange"></span>
            <el-tooltip placement="top">
              <i class="el-icon-bell"></i>
            </el-tooltip>
          </div>
          <el-popover placement="bottom" trigger="hover">
            <div>
              <!-- <p
                v-if="loginType == 0"
                style="cursor: pointer; margin-bottom: 8px"
                @click="urlChange('/system')"
              >
                <span style="margin-left: 6px">系统设置</span>
              </p>
              <p
                v-if="loginType == 0 || loginType == 1"
                style="cursor: pointer; margin-bottom: 8px"
                @click="urlChange('/home')"
              >
                <span style="margin-left: 6px">切换大屏</span>
              </p> -->
              <p style="cursor: pointer" @click="indexModal = 1">
                <span style="margin-left: 6px">修改密码</span>
              </p>
            </div>
            <el-button slot="reference">{{ name }}</el-button>
          </el-popover>
          <!-- <span>{{name}}</span>  -->
          <span class="unmean" @click="out()">退出</span>
        </div>
      </header>
      <div class="content">
        <router-view />
      </div>
    </div>
    <div class="indexModal" v-if="indexModal == 1">
      <div class="inner">
        <p class="title">
          <span>修改密码</span>
          <span @click="indexModal = 0" class="pointer">
            <i class="el-icon-circle-close"></i>
          </span>
        </p>
        <div class="modal_item3">
          <div class="input_item">
            <p>旧密码：</p>
            <el-input v-model="form.oldPassword" size="mini"></el-input>
          </div>
          <div class="input_item">
            <p>新密码：</p>
            <el-input
              v-model="form.newPassword"
              show-password
              size="mini"
            ></el-input>
          </div>
          <div class="input_item">
            <p>确认新密码：</p>
            <el-input
              v-model="form.newPassword1"
              show-password
              size="mini"
            ></el-input>
          </div>
        </div>
        <div class="foot">
          <button class="finish" @click="passWordChange">完成</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  loginedAumenu,
  getUserInfo,
  logout,
  editPwd,
  queryAllPermission,
  doLoginByEmpCode
} from "@/api/apis.js";
import Utils from "@/utils/util.js";
import { timestampToTime } from "@/utils/getTime.js";
export default {
  data() {
    return {
      officeId: "",
      offices: [],
      currentPath: "",
      bgc: "1",
      menu: 1,
      headList: [],
      name: "",
      indexModal: 0,
      form: {},
      loginType: 1000,
      menuData: [],
      //当前展开菜单
      menuTitle: 1,
      headActive: 0,
      headactive: 100,
      isCollapse: false
    };
  },
  mounted() {},
  methods: {
    //关闭标签
    colse() {
      this.headList = [{ url: this.$route.path, name: this.$route.meta }];
    },
    menuChange(i) {
      this.menu = i;
      this.isCollapse = !this.isCollapse;
    },
    //修改密码
    passWordChange() {
      let flag = true;
      if (this.form.oldPassword == this.form.newPassword) {
        this.$message.error("新旧密码一致，请重新输入");
        flag = false;
      }
      if (this.form.oldPassword == this.form.newPassword1) {
        this.$message.error("两次输入密码不一致，请重新输入");
        flag = false;
      }
      if (flag) {
        let data = {
          orginlPwd: this.form.oldPassword,
          newPwd: this.form.newPassword,
          reNewPwd: this.form.newPassword1
        };
        editPwd(data).then(res => {
          if (res.code == 200) {
            this.$message({
              type: "success",
              message: "修改成功，请重新登陆"
            });
            this.indexModal = 0;
            this.out();
          } else {
            this.$message.error(res.data.msg);
          }
        });
      }
    },
    //加入数组
    addList(url, name, sort) {
      let obj = { url, name, sort };
      let flag = true;
      this.headList.forEach(v => {
        if (v.url == obj.url) {
          flag = !flag;
        }
      });
      if (flag) {
        this.headList.push(obj);
      }
    },
    //删除
    deleteItem(item) {
      if (this.headList.length == 1) {
        return;
      }
      let index = 0;
      this.headList.forEach((v, i) => {
        if (v.url == item.url) {
          index = i;
        }
      });
      this.headList.splice(index, 1);
      if (this.headList.length == index) {
        this.$router.push(this.headList[index - 1].url);
      } else {
        this.$router.push(this.headList[index].url);
      }
    },
    //退出
    out() {
      logout();
      localStorage.removeItem("p");
      localStorage.removeItem("perArr");
      this.$router.push("/");
    }
  },
  created() {
    let str = window.location.href;
    // let i = str.indexOf("=");
    // let code = str.substr(i + 1);
    let i = str.split("=");
    let code = i[1];    
    if (code!=undefined) {
      doLoginByEmpCode({ empCode: code }).then(res => {
        if (res.code == 200) {
          //加解密
          var p = Utils.encrypt(res.data.tokenValue);
          localStorage.setItem("p", p);
          this.currentPath = this.$route.path;
          getUserInfo().then(res => {
            if (res.code == 200) {
              this.name = res.data.nickName;
              this.$message({
                dangerouslyUseHTMLString: true,
                message: `
            <p style='margin-bottom:10px'>上次登录时间：${timestampToTime(
              res.data.lastLoginDate
            )}</p>
            <p>  上次登录IP：${res.data.lastLoginIp}</p>
          `
              });
            }
          });
          //解密
          // let t = localStorage.getItem("t");
          // this.loginType = Utils.decrypt(t);
          //权限
          queryAllPermission().then(res => {
            let perArr = res.data.map(item => {
              item.permission = item.permission.split(":")[1];
              return item;
            });
            localStorage.setItem("perArr", JSON.stringify(perArr));
          });
          //菜单
          loginedAumenu().then(res => {
            this.menuData = res.data;
          });
        } else {
          this.$message.error(res.data.msg);
        }
      });
    } else {     
      console.log('mimadenglu');
      
      // let u1 = localStorage.getItem("p");
      // if (u1 == null) {
      //   this.$router.push("/");
        
      // }
      //权限
        queryAllPermission().then(res => {
          let perArr = res.data.map(item => {
            item.permission = item.permission.split(":")[1];
            return item;
          });
          localStorage.setItem("perArr", JSON.stringify(perArr));
        });
         getUserInfo().then(res => {
            if (res.code == 200) {
              this.name = res.data.nickName;
              this.$message({
                dangerouslyUseHTMLString: true,
                message: `
            <p style='margin-bottom:10px'>上次登录时间：${timestampToTime(
              res.data.lastLoginDate
            )}</p>
            <p>  上次登录IP：${res.data.lastLoginIp}</p>
          `
              });
            }
          });
        //菜单
        loginedAumenu().then(res => {
          this.menuData = res.data;
        });
    }
  }
};
</script>

<style lang="less">
.index {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  .light {
    filter: brightness(1.3) !important;
  }
  .aside {
    height: 100%;
    background-color: #e9e9e9;
    display: flex;
    flex-direction: column;
    position: relative;
    .top {
      padding-left: 16px;
      width: 256px;
      box-sizing: border-box;
      .img {
        margin: 56px 0 8px 0;
      }

      .title_main {
        color: #444;
        font-weight: 700;
        letter-spacing: 2px;
        margin-bottom: 66px;
        font-size: 14px;
      }
    }
    .bottom_img {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 150px;
      height: 170px;
    }
    .el-menu {
      border: none;
      background-color: transparent;
      .el-icon-s-home {
        margin-left: 4px;
      }
      .el-menu-item.is-active {
        color: #d1484f;
        background-color: #fff;
      }
      .img2 {
        display: inline-block;
        width: 32px;
        height: 24px;
        filter: brightness(0.7);
      }
      .title {
        font-size: 14px;
      }
      li.el-menu-item {
        height: 45px;
        line-height: 45px;
        padding-left: 23px !important;
        font-weight: 700;
        // font-family:"PingFang SC";
      }
      .el-submenu__title:hover {
        background-color: #fff !important;
      }
      .el-menu-item-group__title {
        padding: 0px;
      }
      .el-icon-arrow-down:before {
        content: "";
      }
      .el-submenu {
        .el-submenu__title {
          height: 32px;
          line-height: 32px;
          padding: 0px 10px 0px 0px !important;
          font-size: 14px;
          margin: 12px 22px 12px 24px;
          span {
            font-weight: 700;
          }
          .btn {
            border-radius: 16px;
            width: 124px;
            background-color: #d62829 !important;
            color: white !important;
          }
        }
        .el-menu-item {
          height: 30px;
          line-height: 30px;
          padding-left: 64px !important;
        }
      }
    }
  }
  .main {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    header {
      width: 100%;
      height: 40px;
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      cursor: pointer;
      .left {
        display: flex;
        flex: 1;
        overflow: hidden;
        .arrow {
          width: 42px;
          line-height: 40px;
          color: #b2b2b2;
          border-left: 1px solid #e7e8ea;
          border-right: 1px solid #e7e8ea;
          padding: 0 20px;
          cursor: pointer;
          span {
            display: inline-block;
            width: 8px;
            height: 8px;
            border-top: solid 1px #b2b2b2;
            border-right: solid 1px #b2b2b2;
            transform: rotate(-135deg);
          }
        }
        .head {
          line-height: 40px;
          border-right: solid 1px #e7e8ea;
          width: 120px;
          box-sizing: border-box;
          text-align: center;
          color: rgb(114, 112, 112);
          position: relative;
          .slide-fade-enter-active {
            transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
          }
          .slide-fade-leave-active {
            transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
          }
          .slide-fade-enter,
          .slide-fade-leave-to,
          .slide-fade-leave-active {
            // transform: translateX(120px);

            opacity: 0;
            // width: 0px;
          }
          .close {
            margin-left: 6px;
            .close_img {
              position: relative;
              top: 2px;
              &:hover {
                // color: #e25454;
                filter: brightness(0.7);
              }
            }
          }

          .head_border {
            position: absolute;
            display: inline-block;
            top: 0px;
            width: 100%;
            height: 2px;
            background-color: #2f3c50;
            left: 0px;
          }

          button {
            width: 11px;
            height: 12px;
            border-radius: 50%;
            margin: 0 0 0 12px;
            color: white;
            background-color: #bfbfbf;
            font-size: 10px;
            line-height: 10px;
            cursor: pointer;
          }
        }
        .head_active {
          background-color: #ebf0f5;
          color: #2f3c50;
        }
      }
      .right {
        display: flex;
        line-height: 40px;

        // p:first-child{
        //   width: 60px;
        // }
        .message {
          position: relative;
          .orange {
            position: absolute;
            display: inline-block;
            height: 6px;
            width: 6px;
            border-radius: 50%;
            background-color: #fea900;
            top: 9px;
            left: 29px;
          }
        }

        select {
          border-style: none;
          border-right: 1px solid #d3d6da;
        }
        i {
          font-size: 20px;
          margin-left: 16px;
          // position: relative;
          // top: 16px;
          // height: 20px;
        }
        .el-button {
          padding: 0;
          border: none;
          color: #000;
          margin-left: 10px;
        }
        .unmean {
          margin: 0 20px 0 10px;
          cursor: pointer;
        }
      }
    }
    .content {
      flex: 1;
      display: flex;
      background-color: #ebf0f5;
      .el-input__inner:hover {
        border-color: #15bb9b;
      }
      .el-input__inner:focus {
        border-color: #15bb9b;
      }
      .el-input.is-focus .el-input__inner {
        border-color: #15bb9b;
      }
    }
  }
  .indexModal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0);
    z-index: 100;
    display: flex;
    align-items: center;
    .inner {
      width: 450px;
      height: 400px;
      box-sizing: border-box;
      background-color: white;
      margin: auto;
      font-size: 12px;
      display: flex;
      flex-direction: column;
      border-radius: 10px;
      box-shadow: 1px 1px 10px #888888;
      position: relative;
      .title {
        color: #2b2e36;
        line-height: 50px;
        font-weight: bold;
        font-size: 12px;
        display: flex;
        justify-content: space-between;
        padding: 0 30px;
        border-bottom: 1px solid #dddddd;
        i {
          font-size: 18px;
          font-weight: 600;
          cursor: pointer;
        }
      }
      .modal_item3 {
        padding: 0 50px;
        margin: 24px 0;
        .input_item {
          display: flex;
          margin-bottom: 13.5px;
          p {
            width: 80px;
            line-height: 28px;
          }
          .el-input {
            width: 280px;
          }
        }
      }
      .foot {
        width: 100%;
        position: absolute;
        bottom: 24px;
        .finish {
          display: block;
          width: 350px;
          height: 40px;
          border-radius: 6px;
          background-color: #d62829;
          color: #fff;
          cursor: pointer;
          margin: 0 auto;
        }
        .confirm_delete {
          display: block;
          width: 350px;
          height: 40px;
          border-radius: 6px;
          background-color: #ff0101;
          color: #fff;
          cursor: pointer;
          margin: 0 auto;
        }
      }
    }
  }
}
</style>